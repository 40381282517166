import React, { useEffect, useState } from "react";
import { StyledBulkAdvisorAssignment } from "./BulkAdvisorAssignmentComponent.styled";
import CustomDropdown from "../CsrAssignment/components/Dropdown";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Button from "../../commonComponents/Button";
import Toggle from "../../commonComponents/Toggle/Toggle";
import PopUPCms from "../../commonComponents/PopUPCms";
import Backdrop from "../../commonComponents/PopUPCms/BackDrop";
import AdvisorService from "../../Services/AdvisorService";
import toast from "react-hot-toast";
import BasicExampleDataGrid from "../../components/SuperSaverTable/SuperTable";
import { Spin, Tooltip } from "antd";
import BulkShiftConfirmationPopup from "../../components/PopupForBulkShiftConfirmation";
import { getFullName } from "../../utils/textUtil";
import { allPlanOptions, buttonStyling, columns, getRowFunction, handlePlanChange, handleStateChange } from "./constants";
import LoaderPage from "../../commonComponents/LoaderPage";

const BulkAdvisorAssignmentComponent = () => {
  const [formState, setFormState] = useState({fromAdvisor: null, toAdvisor: null, selectedAdvisorType: "PRIMARY", selectedUsers: []});
  const [advisorOptions, setAdvisorOptions] = useState({fromAdvisorOptions: [], toAdvisorOptions: []});
  const [fetchClientsLoader, setFetchClientsLoader] = useState(false);
  const [data, setData] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [bulkAssignmentLoader, setBulkAssignmentLoader] = useState(false);
  const [fetchAdvisorsLoader, setFetchAdvisorsLoader] = useState(false);

  const rowFunction = getRowFunction(formState);

  const handleRowSelection = (newSelection) =>  handleStateChange({key: "selectedUsers", value: newSelection, setState: setFormState});

  const handleButtonClick = () => setShow(true);

  const handleCross = () => setShow(false);

  const handlePlanSelect = handlePlanChange(setSelectedPlans);

  const getAdvisors = async () => {
    setFetchAdvisorsLoader(true);
    try {
      const response = await AdvisorService.getAdvisors();
      const advisors =
        response?.data?.map((item) => {
          return {
            value: item?.user_details?.external_id,
            label: getFullName(item?.user_details),
          };
        }) || [];
      handleStateChange({key: "fromAdvisorOptions", value: advisors, setState: setAdvisorOptions})
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }finally{
      setFetchAdvisorsLoader(false);
    }
  };

  const handleYes = async () => {
    setBulkAssignmentLoader(true);
    const req = {
      user_ids: formState?.selectedUsers,
      advisor_id: formState?.fromAdvisor?.value,
      advisor_type: formState?.selectedAdvisorType,
      new_advisor_id: formState?.toAdvisor?.value,
    };
    try {
      const responsePromise = AdvisorService.bulkAssignExpert(req);
      const response = await toast.promise(responsePromise, {
        loading: "Shifting clients...",
        success: "Clients shifteded successfully!",
        error: "Oops!! Something went wrong!",
      });

      getAdvisors();
      setData([]);
      setFormState((state) => {
        return {
          ...state,
          selectedUsers: [],
          fromAdvisor: null,
          toAdvisor: null,
        };
      });
      setShow(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setBulkAssignmentLoader(false);
    }
  };

  const getAdvisorClients = async (advisor_id) => {
    setFetchClientsLoader(true);
    try {
      const responsePromise = AdvisorService.getAllClientsOfAdvisor(
        advisor_id,
        null
      );
      const response = await responsePromise;
      const clientList = response?.data.map((item) => {
        return {
          user_id: item?.user_id,
          category_type: item?.category_type,
          user_response: item?.user_response,
          advisor_details: item?.advisor_details,
          secondary_advisor_details: item?.secondary_advisor_details,
          created_at: item?.created_at,
        };
      });
      setData(clientList);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setFetchClientsLoader(false);
    }
  };

  useEffect(() => {
    getAdvisors();
  }, []);

  useEffect(() => {
    const filteredAdvisors = advisorOptions?.fromAdvisorOptions?.filter(
      (item) => item?.value !== formState?.fromAdvisor?.value
    );
    formState?.fromAdvisor && handleStateChange({key: "toAdvisorOptions", value: filteredAdvisors, setState: setAdvisorOptions})
  }, [advisorOptions?.fromAdvisorOptions, formState?.fromAdvisor]);

  useEffect(() => {
    setFormState((state) => {
      return {
        ...state,
        selectedUsers: [],
        toAdvisor: null,
      };
    });
    formState?.fromAdvisor && getAdvisorClients(formState?.fromAdvisor?.value);
  }, [formState?.fromAdvisor]);

  useEffect(() => {
    handleStateChange({key: "selectedUsers", value: [], setState: setFormState});
    const plans = selectedPlans?.map((item) => item?.value);
    const filterData = data?.filter((item) =>
      plans?.includes(item?.category_type)
    );
    setFilteredData(filterData);
  }, [data, selectedPlans]);

  if(fetchAdvisorsLoader) return <LoaderPage/>;

  return ( 
    <StyledBulkAdvisorAssignment>
      <h2>Bulk Advisor Assignment</h2>
      <div className="dropdown-container">
        <CustomDropdown
          heading="Select Old Advisor"
          options={advisorOptions?.fromAdvisorOptions}
          handleSelect={(selectedValue) => handleStateChange({ key: "fromAdvisor", value: selectedValue, setState:setFormState }) }
          value={formState?.fromAdvisor}
        />
        <ArrowRightAltIcon className="arrow" />
        <CustomDropdown
          heading="Select New Advisor"
          options={advisorOptions?.toAdvisorOptions}
          handleSelect={(selectedValue) => handleStateChange({ key: "toAdvisor", value: selectedValue, setState: setFormState })}
          value={formState?.toAdvisor}
        />
        <Tooltip title="Select the assignment type">
          <div className="toggle">
            <Toggle
              selectedValue={formState?.selectedAdvisorType || "Primary"}
              firstValue="Primary"
              secondValue="Secondary"
              handleSelectedValue={(value) => handleStateChange({ key: "selectedAdvisorType", value: value.toUpperCase(), setState: setFormState })}
            />
          </div>
        </Tooltip>
        <Button
          onButtonClick={ formState?.fromAdvisor && formState?.toAdvisor && handleButtonClick }
          style={{ ...buttonStyling(formState)}}
          buttonName="Shift"
        />
      </div>
      <div className="plan-type-select">
        <CustomDropdown
          heading="Select Plan Type Filter"
          options={allPlanOptions}
          closeMenuOnSelect={false}
          defaultValue={null}
          value={selectedPlans}
          handleSelect={handlePlanSelect}
          width={450}
          isMulti
        />
      </div>
      <div className="users-table">
        <Spin spinning={fetchClientsLoader}>
          <BasicExampleDataGrid
            data={filteredData}
            columns={columns}
            rowFunction={rowFunction}
            checkboxSelection={true}
            onRowSelectionModelChange={handleRowSelection}
            rowSelectionModel={formState?.selectedUsers}
          />
        </Spin>
      </div>
      <PopUPCms width={40} left={250} show={show} handleCross={handleCross}>
        <BulkShiftConfirmationPopup
          selectedUsers={formState?.selectedUsers}
          fromAdvisor={formState?.fromAdvisor}
          toAdvisor={formState?.toAdvisor}
          handleYes={handleYes}
          handleCross={handleCross}
          selectedAdvisorType={formState?.selectedAdvisorType}
          spinning={bulkAssignmentLoader}
        />
      </PopUPCms>
      <Backdrop onHideModelClick={handleCross} show={show} />
    </StyledBulkAdvisorAssignment>
  );
};

export default BulkAdvisorAssignmentComponent;
