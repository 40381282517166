import toast from "react-hot-toast";
import SuperSaverDiscussionBookService from "../../Services/SuperSaverClientChat";
import { globalNotificationAction } from "./store/reducer";

const getUserData = (
  advisorRefreshStatus,
  dispatch,
  setUserData,
  setLoader,
  advisorId,
  setRecallTimer,
  recallTimer,
  selectedUserType,unreadMsgFilter,isLoaderActive,setIsLoaderActive
) => {
  clearTimeout(recallTimer);
 
  if (advisorRefreshStatus) {

setLoader(isLoaderActive);
    SuperSaverDiscussionBookService.getAdvisorsActiveChatsNew(
      advisorId,
      selectedUserType,unreadMsgFilter
    ).then(function (res) {
      const value = res?.user_and_chats||[];
      dispatch({
        type: globalNotificationAction.SET_LAST_MESSAGE_TIMESTAMP,
        payload: res ? res?.last_activity_time : null,
      });
      value?.length>0 &&
        setUserData(
          value?.map((d, i) => {
            return {
              ...d,
              advisor_details: {
                external_id: advisorId,
              },
              user_response: d?.user_details || {},
            };
          })
        );
      clearTimeout(recallTimer);
      const dataTimeout = setTimeout(() => {
        getUserData(
          advisorRefreshStatus,
          dispatch,
          setUserData,
          setLoader,
          advisorId,
          setRecallTimer,
          recallTimer,
          selectedUserType
        );
      }, 30000);
      setRecallTimer(dataTimeout);
      if (!advisorRefreshStatus) {
        clearTimeout(recallTimer);
      }

      setLoader(false);
      setIsLoaderActive(false);
    })
    .catch((er) => {
      console.warn(er?.message,er)
    })
  } else {
    clearTimeout(recallTimer);
  }
};

export { getUserData };
