import styled from "styled-components";
import { dim, theme } from "../../styles/theme";

export const StyledBulkShiftConfirmationPopup = styled.div`
  background: ${theme.color.white_0};
  margin: auto;
  height: auto;
  border-radius: ${dim._8px};
  width: ${dim._scale(400)};
  position: relative;
  box-sizing: border-box;

  .popBox {
    background-color: ${theme.color.white_10};
    border-radius: ${dim._0px} ${dim._0px} ${dim._8px} ${dim._8px};
  }
  .popContent {
    font-size: ${dim._18px};
    margin: ${dim._0px} ${dim._30px} ${dim._0px} ${dim._30px};
    color: ${theme.color.grey_100};
    text-align: center;
    padding-top: ${dim._30px};
    padding-bottom: ${dim._30px};

    .highlighted-text {
      font-weight: bold;
    }
  }
  .popButton {
    width: 50%;
    height: ${dim._50px};
    border-radius: ${dim._0px} ${dim._0px} ${dim._0px} ${dim._8px};
    text-align: center;
    padding-top: ${dim._14px};
    font-size: ${dim._18px};
    box-sizing: border-box;
  }
  .yes {
    color: ${theme.color.white_0};
    background: ${theme.color.blue_10};
    cursor: pointer;
  }
  .no {
    border-radius: ${dim._0px} ${dim._0px} ${dim._8px} ${dim._0px};
    border-top: 1px solid ${theme.color.grey_120};
    cursor: pointer;
  }
  .closePop {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: ${dim._2px} ${dim._4px};
    line-height: ${dim._20px};
    right: -${dim._10px};
    top: -${dim._10px};
    font-size: ${dim._24px};
    background: ${theme.color.white_0};
    border-radius: ${dim._18px};
  }
`;