import styled from "styled-components";
import { dim } from "../../styles/theme";

export const StyledBulkAdvisorAssignment = styled.div`
  width: 100%;
  .dropdown-container {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: left;
    gap: ${dim._40px};
    margin: ${dim._20px} ${dim._0px} ${dim._0px} ${dim._0px};
    box-sizing: border-box;
  }

  .plan-type-select{
    margin-top: ${dim._20px};
    align-items: center;
    display: flex;
    justify-content: left;
  }

  .toggle {
    margin-left: ${dim._50px};
    margin-bottom: -${dim._24px};
    box-sizing: border-box;
  }

  .arrow {
    margin-bottom: -${dim._24px};
    box-sizing: border-box;
  }

  .users-table {
    padding-top: ${dim._30px};
    box-sizing: border-box;
  }
`;
