import { InputLabel } from "@mui/material";
import React from "react";
import Select from "react-select";
import styled from "styled-components";
import { dim } from "../../../../styles/theme";

const CustomDropdown = ({
  heading = "",
  width = 250,
  options,
  handleSelect,
  value,
  noOptionsMessage = () => "No Option",
  isMulti = false,
  closeMenuOnSelect = true,
}) => {
  return (
    <StyledCustomDropdown width={width}>
      <InputLabel>{heading}</InputLabel>
      <Select
        closeMenuOnSelect={closeMenuOnSelect}
        options={options}
        onChange={handleSelect}
        value={value}
        noOptionsMessage={noOptionsMessage}
        isMulti={isMulti}
      />
    </StyledCustomDropdown>
  );
};

export default CustomDropdown;

const StyledCustomDropdown = styled.span`
  width: ${(props) => dim._scale(props.width)};
`;
