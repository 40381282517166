import { httpClient } from "./httpUtil";

const AdvisorService = {
  getAdvisors: () => {
    return httpClient.get(`advisors/v1/all/cms`);
  },
  getAdvisorUnreadChat: () => {
    return httpClient.get(`/discussion-message/v1/unread-chats`);
  },
  getAllUserByAdvisorId: (advisor_id, advisor_type, user_type, filter_by) => {
    return httpClient.get(
      `super-saver-user/v1/all/${advisor_id}/get-users/internal`,
      { advisor_type, user_type, filter_by }
    );
  },
  getAllClientsOfAdvisor: (advisor_id, advisor_type) => {
    const url = `super-saver-user/v1/${advisor_id}/get-all-clients`;
    const params = { advisor_type };
    return httpClient.get(url, params);
  },
  bulkAssignExpert: (req) => {
    const url = `advisors/v1/bulk-assign-expert`;
    return httpClient.put(url, req);
  },
};

export default AdvisorService;
