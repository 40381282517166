import { dim } from "../../styles/theme";

export const columns = [
  { field: "name", headerName: "Name", width: 150 },
  { field: "email", headerName: "E-mail", width: 200 },
  { field: "mobile", headerName: "Mobile", width: 150 },
  { field: "service_type", headerName: "Plan Type", width: 150 },
  { field: "advisorType", headerName: "Advisor Type", width: 150 },
  { field: "otherAdvisor", headerName: "Other Advisor", width: 150 },
];

export const planOptions = [
  {
    label: "Basic",
    value: "BASIC",
  },
  {
    label: "Premium",
    value: "PREMIUM",
  },
  {
    label: "Advanced",
    value: "ADVANCED",
  },
  {
    label: "Elite",
    value: "ELITE",
  },
];

export const allPlanOptions = [
  {
    label:"All",
    value: "ALL",
  },
  ...planOptions,
];

export const handleStateChange = ({ key, value, setState }) => {
  setState((state) => {
    return {
      ...state,
      [key]: value,
    };
  });
};

 export const buttonStyling = (state) => {return {
  position: "absolute",
  right: `${dim._50px}`,
  opacity: state?.fromAdvisor && state?.toAdvisor ? "1" : "0.5",
  cursor: state?.fromAdvisor && state?.toAdvisor ? "pointer" : "not-allowed",
}};

export const getRowFunction = ( formState) => {
  return (value, index) => { 
    const {
      first_name: primaryAdvisorFirstName,
      last_name: primaryAdvisorLastName,
    } = value?.advisor_details || {};
    const {
      first_name: secondaryAdvisorFirstName,
      last_name: secondaryAdvisorLastName,
    } = value?.secondary_advisor_details || {};
    const secondaryAdvisor =
      (secondaryAdvisorFirstName &&
        `${secondaryAdvisorFirstName || ""} ${secondaryAdvisorLastName || ""}`) ||
      "Not Assigned";
    const primaryAdvisor =
      (primaryAdvisorFirstName &&
        `${primaryAdvisorFirstName || ""} ${primaryAdvisorLastName || ""}`) ||
      "Not Assigned";
    return {
      id: value?.user_id,
      name: value?.user_response?.first_name,
      service_type: value?.category_type,
      mobile: value?.user_response?.mobile,
      email: value?.user_response ? value?.user_response.email : "",
      advisorType:
        value?.advisor_details?.external_id === formState?.fromAdvisor?.value
          ? "PRIMARY"
          : "SECONDARY",
      otherAdvisor:
        value?.advisor_details?.external_id === formState?.fromAdvisor?.value
          ? secondaryAdvisor
          : primaryAdvisor,
    };
  };
}

export const handlePlanChange = (setSelectedPlans) => {
  return (selected) => {
    if (selected && selected.some((option) => option?.value === "ALL")) {
      setSelectedPlans(planOptions);
    } else {
      setSelectedPlans(selected);
    }
  };
}