import React from "react";
import FlexRow from "../../commonComponents/FlexRow/FlexRow";
import { Spin } from "antd";
import { StyledBulkShiftConfirmationPopup } from "./BulkShiftConfirmationPopup.styled";

const BulkShiftConfirmationPopup = ({
  selectedUsers,
  fromAdvisor,
  toAdvisor,
  handleYes,
  handleCross,
  selectedAdvisorType,
  spinning
}) => {
  return (
    <StyledBulkShiftConfirmationPopup>
      <span className="closePop" onClick={() => handleCross()}>
        &times;
      </span>
      <Spin spinning={spinning}>
      <div className="popContent">
          Are you sure you want to assign{" "}
          <span className="highlighted-text">{toAdvisor?.label}</span> as{" "}
          <span className="highlighted-text">{selectedAdvisorType}</span>{" "}
          advisor of{" "}
          <span className="highlighted-text">{selectedUsers?.length || 0}</span>{" "}
          clients ?
      </div>

      <div className="popBox">
        <div>
          <FlexRow>
            <span
              className={`popButton yes`}
              onClick={() => {
                handleYes();
              }}
            >
              Yes
            </span>
            <span className="popButton no" onClick={() => handleCross()}>
              No
            </span>
          </FlexRow>
        </div>
      </div>
      </Spin>
    </StyledBulkShiftConfirmationPopup>
  );
};


export default BulkShiftConfirmationPopup;
