import { getAuth } from "@firebase/auth";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import ClientProfile from "../../container/AssignedUsers/components/ClientProfile";
import { getUserIncome } from "../../Services/SuperSaver";
import { convertNumberIntoINR } from "../../utils/deductionView";
import { incomencolumns } from "../../utils/incomeView";
import BasicExampleDataGrid from "../SuperSaverTable/SuperTable";

import { IncomeViewStyled } from "./IncomeView.styled";
import LayoutHOC from "../UserSideBar";

const IncomeView = ({ user_id }) => {
  const [Data, setData] = useState([]);

  const state = useLocation();

  const getIncome = () => {
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (token) {
          state.state &&
            getUserIncome(token, state.state.user_id).then((data) => {
              var incomeArr = data?.income_details?.map((d, i) => {
                return d?.type_details.map((incomeType, i) => {
                  return incomeType?.sub_type_details?.map(
                    (sub_type_details, i) => {
                      return {
                        ...sub_type_details,
                        type: d?.type,
                        total_income: d?.total_income,
                        sub_type: incomeType?.sub_type,
                        sub_type_total_income: incomeType?.total_income,
                      };
                    }
                  );
                });
              }) || [];
              let newIncomeArr = [];

              for (var i = 0; i < incomeArr.length; i++) {
                newIncomeArr = newIncomeArr.concat(incomeArr[i]);
              }
              let incomeDocumentArr = [];
              for (var j = 0; j < newIncomeArr.length; j++) {
                incomeDocumentArr = incomeDocumentArr.concat(newIncomeArr[j]);
              }
              setData(incomeDocumentArr);
            });
        });
      }
    });
  };

  useEffect(() => {
    getIncome();
  }, []);

  const timeStampDate = (timeStamp) => {
    var date = new Date(timeStamp);
    var yyyy = date.getFullYear();
    var mm = date.getMonth() + 1; // Months start at 0!
    var dd = date.getDate();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    date = `${yyyy}-${mm}-${dd}`;
    return date;
  };

  const getDate = (startDate, endDate) => {
    if (endDate !== undefined) {
      return `${timeStampDate(startDate)
        .split("-")
        .reverse()
        .join("/")} - ${timeStampDate(endDate).split("-").reverse().join("/")}`;
    } else {
      return `${timeStampDate(startDate).split("-").reverse().join("/")}`;
    }
  };

  function rowFunction(value, index, array) {
    return {
      id: index,
      category: value.type,
      sub_category: value.sub_type,
      income_amount: convertNumberIntoINR(value.income),
      view: { value: value.doc_links.links[0], user: state.state.user_id },
      duration: getDate(value.start_date, value.end_date),
      description:
        value.description === "" ? "No-description" : value.description,
    };
  }
  return (
    <div className="" style={{ width: "100%" }}>
      <ClientProfile userDetails={state.state} view={"Income View"} />
      <IncomeViewStyled>
        <div className="resultS">
          <div className=""></div>
          <CardAddSlot>
            <BasicExampleDataGrid
              data={Data}
              columns={incomencolumns}
              rowFunction={rowFunction}
            />
          </CardAddSlot>
        </div>
      </IncomeViewStyled>
    </div>
  );
};

export default LayoutHOC(IncomeView);
