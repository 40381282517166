import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";

export default function BasicExampleDataGrid({
  data = [],
  columns,
  handleOnCellClick=()=>{},
  rowFunction,
  enablePaging = false,
  checkboxSelection=false,
  onRowSelectionModelChange=()=>{},
  loading=false,
  rowSelectionModel,
  pathname = "/",
}) {
  const { id = 0 } = useParams();
  const navigate = useNavigate();
  const rows = data.map(rowFunction);
  const initalPageSize = 100;

  return (
    <div style={{ width: "100%" , height: rows?.length === 0 ? "400px" : "100%" }} key={id}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: initalPageSize,
            },
          },
        }}
        checkboxSelection={checkboxSelection}
        onRowSelectionModelChange={onRowSelectionModelChange}
        loading={loading}
        rowSelectionModel={rowSelectionModel}
        onCellClick={(e) => {
          handleOnCellClick(e, navigate);
        }}
        localeText={{ noRowsLabel:'No Result Found' }}
        pageSizeOptions={enablePaging ? [5, 10, 25, 50, 100] : undefined}
      />
    </div>
  );
}


