import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import AdvisorContext from "../../Context/Advisor/AdvisorContext";
import { SidebarStyle, StyledArticleCategory } from "./Sidebar.styled";
import Badge from "../../commonComponents/badge";
import { useSelector } from "react-redux";
import { getArticleCategories } from "./constant";
import { USER_ROLE } from "../../constants/userRole";
import { Collapse } from "antd";
import { Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";

const { Panel } = Collapse;
const { SubMenu } = Menu;

const SideBar = ({ SideBarData, className, userType = " ",showAuthorSidebar = true }) => {
  const globalNotification = useSelector(
    (state) => state.globalNotifications.notification
  );
  const location = useLocation();
  const pathname = location.pathname;
  const a = useContext(AdvisorContext);
  const auth = getAuth();
  const [messages, setMessages] = useState(0);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      user.auth.currentUser
        .getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          a.setAppContext(idToken);
        })
        .catch((error) => {
          toast.error(error?.message || "Something went wrong!!");
        });
    }
  });

  useEffect(() => {
    const totalUnreadSessions =
      globalNotification &&
      globalNotification.reduce(
        (total, item) => total + item.unread_messages,
        0
      );
    setMessages(totalUnreadSessions);
  }, [globalNotification]);

  function matchPath(activePath,actualPath,extractedPath){
    const exactMatch = (activePath==actualPath);
    const partialMatch = (activePath==extractedPath);
    return exactMatch || partialMatch;
  }

  const getNavLinkClass = (item) => {
    const urlPath = pathname;
    const extractedPath = urlPath.split('/').slice(0, -1).join('/');
    const actualPath = pathname;
  
    let isActive = false;
  
    if (item.active.some((activePath) => matchPath(activePath, actualPath, extractedPath))) {
      isActive = true;
    }
  
    if (item?.inactive?.some((path) => path === actualPath)) {
      isActive = false;
    }
  
    return isActive ? "item active" : "item inactive";
  };
  

  const ArticleMenuForAdmin = ({ getArticleCategories }) => {
    return (
      <Menu mode="inline" defaultOpenKeys={["sub1"]}>
        <SubMenu
          key="sub1"
          title={
            <span>
              <span>Article Category</span>
            </span>
          }
        >
          {getArticleCategories?.map((item, index) => (
            <Menu.Item
              key={index}
              className={pathname.includes(item?.pathName) ? "active" : ""}
            >
              <NavLink
                to={`articles/${item?.pathName}`}
                style={{ background: "none" }}
              >
                {item?.name}
              </NavLink>
            </Menu.Item>
          ))}
        </SubMenu>
      </Menu>
    );
  };

  return ( 
    <SidebarStyle className={className}>
      {userType !== USER_ROLE.AUTHOR &&
        SideBarData.map((item, index) => 
         { 
          return <NavLink
            to={{
              pathname: `/${item.pathName}`,
              search: item?.state?.user_id ? `?user_id=${item?.state?.user_id}` : '',
            }}
            state={item.state && item.state}
            key={index}
            className={() => getNavLinkClass(item)}
          >
            {item.name}
            {item.id === 7 && messages > 0 ? <Badge value={messages} /> : <></>}
          </NavLink>
          }
        )}
      {userType === USER_ROLE.ADMIN && showAuthorSidebar && (
        <ArticleMenuForAdmin getArticleCategories={getArticleCategories} />
      )}
      {userType === USER_ROLE.AUTHOR && (
        <StyledArticleCategory>
          <div className={"outer-container"}>
            <div className="header-box">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/icons/allArticlesCategoryIcon.svg"
                }
                alt=""
              />
              <p style={{ margin: "0px" }}>Article Category</p>
            </div>

            <div className="links-container">
              {getArticleCategories.map((item, index) => {
                return (
                  <NavLink
                    to={`articles/${item.pathName}`}
                    className={pathname.includes(item.pathName) && "active"}
                    key={index}
                  >
                    {item.name}
                  </NavLink>
                );
              })}
            </div>
          </div>
        </StyledArticleCategory>
      )}
    </SidebarStyle>
  );
};

export default SideBar;
